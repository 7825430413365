import {ProductObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import arrowIcon from 'src/assets/images/icons/down-chevron.svg';
import searchIcon from 'src/assets/images/icons/search.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

export const SearchEngine = observer(() => {
	const MIN_CHARS_REQUIRED = 3;
	const DEBOUNCE_FETCH_DELAY = 1;
	const {translate} = useTranslate();
	const [searchValue, setSearchValue] = useState<string>('');
	const [filteredProducts, setFilteredProducts] = useState<ProductObject[]>([]);
	const debounceTimer = useRef<any>();

	const {
		navigation: {fetchProductsByFilter},
	} = useStore();

	const onValueChanged = (newValue: string) => {
		clearTimeout(debounceTimer.current);
		setSearchValue(newValue);
		debounceTimer.current = setTimeout(async () => {
			if (newValue.length >= MIN_CHARS_REQUIRED) {
				setFilteredProducts(
					await fetchProductsByFilter({
						name: newValue,
					}),
				);
			}
		}, DEBOUNCE_FETCH_DELAY * 1000);
		if (newValue.length < MIN_CHARS_REQUIRED && searchValue.length >= MIN_CHARS_REQUIRED) {
			setFilteredProducts([]);
		}
	};

	const onNavigateProduct = () => {
		setFilteredProducts([]);
	};

	return (
		<div className="search-engine-wrapper">
			<div className="search-engine">
				<ReactSVG className="search-icon" src={searchIcon} />
				<input
					type="text"
					value={searchValue}
					className="search-input"
					placeholder={translate('ProductPage.search')}
					onChange={(e) => onValueChanged(e.target.value)}
					onFocus={() => onValueChanged(searchValue)}
				/>
				{searchValue.length >= MIN_CHARS_REQUIRED && (
					<div className="search-clear__icon" onClick={() => onValueChanged('')} />
				)}
				<div className="search-suggestions__content">
					{filteredProducts.map((product: ProductObject, index: number) => (
						<Link to={`/product/${product.code}`} key={index}>
							<div className="suggestion-product__tile" onClick={() => onNavigateProduct()}>
								<span>{product.name}</span>
								<ReactSVG className="arrow__icon" src={arrowIcon} />
							</div>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
});
