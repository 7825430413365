import {OrderItemObject, ProductType} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import discountStar from 'src/assets/images/discount-star.png';
import {useImage} from 'src/hooks/image.hook';
import {usePrettifyOrderProducts} from 'src/hooks/prettify-order-product.hook';

// TODO: Changes after Tristan updates for real types
interface SumOrderItemProps {
	item: OrderItemObject;
}
export const SumOrderItem = observer((props: SumOrderItemProps) => {
	const {item} = props;
	const product = item.productSnapshot;
	const isCoupon = product.type === ProductType.COUPON;
	const {productItemList} = usePrettifyOrderProducts(item);
	const {image} = useImage(isCoupon ? discountStar : product?.imageUrl);

	return (
		<>
			{product && (
				<div className="sum-basket-item">
					<div className="sum-basket-item__wrap-img">
						<img src={image} alt={product?.name} className="sum-basket-item__wrap-img__image" />
					</div>

					<div className="sum-basket-item__text">
						<div className="sum-basket-item__text__first-line">
							<p className="txt-bold txt-l">
								<span className="txt-primary txt-xl">{item.quantity}</span>
								{isCoupon ? product?.description : product?.name}
							</p>
							{/* TODO: Implement it after Tristan updates */}
							{/* <p className="txt-primary txt-bold">{formatMoney(item.totalPrice)}</p> */}
						</div>

						{!!product.calories && (
							<div className="sum-basket-item__text__second-line">
								<p className="txt-xs">{product.calories} CAL</p>
							</div>
						)}
						<ul className="list__container--no-style">
							{productItemList.map((partInfo: string, index: number) => {
								return (
									<li key={index} className="list__item--no-style txt-xs">
										{partInfo}
									</li>
								);
							})}
						</ul>

						{/* <div className="sum-basket-item__text__third-line">
							<p>Show details</p>
							<div className="round round--down"></div>
						</div> */}

						{/* TODO: Implement it after Tristan updates */}

						{/* {item.partsList && <div className="sum-basket-item__text__fourth-line">
							<div>
								<ul className="list__container--no-style">
									{item.partsList.map((partItem: string) => <li key={partItem} className="list__item--no-style txt-xs">{partItem}</li>)}
								</ul>
							</div>
						</div>} */}
					</div>
				</div>
			)}
		</>
	);
});
